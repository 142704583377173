import React from "react"
import { Link } from "gatsby"
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa"
import Logo from "../images/logo.webp"

const Footer = () => (
  <div>
    <footer className="footer section">
      <div className="bd-container">
        <div className="footer__container bd-grid">
          <div className="footer__content">
            <Link to="/" className="footer__logo">
              <img
                src={Logo}
                className="footer__logo"
                width={300}
                alt="Whrilindo Logo"
              />
            </Link>
            <span className="footer__description">
              PT. Whrilindo Indonesia Perkasa is a Turbine Ventilator and
              Plywood Distributor which was established in 2019 and based in
              Batam, Indonesia.
            </span>

            <div className="footer__social_media">
              <a
                href="https://www.facebook.com/PT.WHRILINDOINDONESIAPERKASA/"
                className="footer__social"
                target="_blank"
                rel="noopener noreferrer"
                aria-labelledby="facebook"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://instagram.com/turbine.ventilator.yoota/"
                className="footer__social"
                target="_blank"
                rel="noopener noreferrer"
                aria-labelledby="instagram"
              >
                <FaInstagram />
              </a>
              <a
                href="https://wa.me/6285390918888"
                className="footer__social"
                target="_blank"
                rel="noopener noreferrer"
                aria-labelledby="whatsapp"
              >
                <FaWhatsapp />
              </a>
            </div>
          </div>

          <div className="footer__content">
            <h3 className="footer__title">Our Services</h3>
            <ul className="footer__menu">
              <li>
                <Link to="/" className="footer__link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="footer__link">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/products" className="footer__link">
                  Products
                </Link>
              </li>
              <li>
                <Link to="/contact" className="footer__link">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer__content">
            <h3 className="footer__title">Get In Touch</h3>
            <ul className="footer__menu">
              <li className="footer__address">
                Kawasan Bintang Industri 2 Blok H2-07, Tg. Uncang - Batam
              </li>
              <li>
                <a
                  href="mailto:whrilingindonesia@gmail.com"
                  className="footer__email"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  whrilingindonesia@gmail.com
                </a>
              </li>
              <li className="footer__phone">+628 539 091 8888</li>
              <li className="footer__phone">+628 228 310 2890</li>
              <li>
                <a
                  href="https://wa.me/6285390918888"
                  className="footer__button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp className="footer__wa" /> Chat on Whatsapp
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>

    <footer className="footer__copy">
      <p className="footer__copy">
        &#169; {new Date().getFullYear()}
        {` `}
        <a href="https://www.whrilindo.com" className="footer__link">
          PT. Whrilindo Indonesia Perkasa
        </a>
        . All right reserved
      </p>
    </footer>
  </div>
)

export default Footer
