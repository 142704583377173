import React, { Component } from "react"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import Img from "../images/logo.webp"

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }
  }

  handleClick = () => {
    this.setState({ active: !this.state.active })
    const nav = document.getElementById("nav-menu")
    if (nav) {
      nav.classList.toggle("show-menu")
    }
  }

  closeMenuClick = () => {
    const nav = document.getElementById("nav-menu")
    if (nav) {
      nav.classList.remove("show-menu")
    }
  }

  render() {
    return (
      <header className="header" id="header">
        <nav className="nav bd-container">
          <div>
            <Link to="/">
              <img src={Img} className="nav__logo" alt="Whrilindo Logo" />
            </Link>
          </div>
          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list">
              <li className="nav__item">
                <Link
                  className="nav__link"
                  activeClassName="active-link"
                  to="/"
                  onClick={() => this.closeMenuClick()}
                >
                  Home
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  className="nav__link"
                  activeClassName="active-link"
                  to="/about"
                  onClick={() => this.closeMenuClick()}
                >
                  About Us
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  className="nav__link"
                  activeClassName="active-link"
                  to="/products"
                  onClick={() => this.closeMenuClick()}
                >
                  Products
                </Link>
              </li>
              <li className="nav__item">
                <Link
                  className="nav__link"
                  activeClassName="active-link"
                  to="/contact"
                  onClick={() => this.closeMenuClick()}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={"nav__toggle " + (this.state.active ? "is-active" : "")}
            id="nav-toggle"
            role="button"
            onClick={this.handleClick}
            onKeyDown={this.handleClick}
            aria-labelledby="toggle"
            tabIndex={-1}
          >
            <i className="toggle">
              <FaBars />
            </i>
          </div>
        </nav>
      </header>
    )
  }
}

export default Navbar
